.tile-container {
    text-decoration: none;
    display: block;

    .image-container {
        position: relative;
        height: 100%;

        .duration {
            position: absolute;
            border-radius: 4px;
            left: 1rem;
            bottom: 1rem;
            white-space: normal;
            color: white;
            line-height: 1.5;
            background-color: rgba(0,0,0,0.4);
            max-width: 100%;
            padding: 0 0.3rem;
            font-size: 1.5rem;

            @media (min-width: 768px) {
                font-size: 1.2rem;
            }
        }

        .play-icon {
            width: 4rem;
            height: 4rem;
            position: absolute;
            top: calc(50% - 2rem);
            left: calc(50% - 2rem);
            stroke: white;
            display: none;
        }

        &:hover {
            .play-icon {
                display: block;
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 3px;
        }

        &.progress-visible .duration {
            bottom: 1.9rem;
        }

        .progress-container {
            height: 0.6rem;
            background-color: rgba(0,0,0,0.4);
            width: calc(100% - 2rem);
            margin: 0 1rem;
            position: absolute;
            bottom: 0.75rem;
            border-radius: 3px;
            overflow: hidden;

            .progress-bar {
                background-color: white;
                height: 0.6rem;
                width: 50%;
            }
        }
    }

    h3 {
        white-space: normal;
        font-size: 1.6rem;
        line-height: 1.5;
        color: white;
        max-width: 100%;
        font-weight: normal;
        margin: 0;

        @media (min-width: 768px) {
            font-size: 1.2rem;
        }
    }

    h4 {
        font-size: 1.6rem;
        color: #666;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;

        @media (min-width: 768px) {
            font-size: 1.2rem;
        }
    }

    &:hover .add-to-playlist {
        display: flex;
    }



    .add-to-playlist {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        display: flex;
        align-items: center;
        display: none;

        ion-icon {
            color: white;
            border-radius: 3px;
            padding-left: 4px;
            padding-top: 2px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            shape-rendering: crispEdges;
            background-color: rgba(0,0,0,0.4);

            @media (min-width: 768px) {
                width: 24px;
                height: 24px;
                padding-left: 4px;
            }

            @media (min-width: 1200px) {
                width: 36px;
                height: 36px;
                padding-left: 4px;
            }

            &:hover {
                background-color: rgba(0,0,0,0.7);
            }
        }

        &:hover .tooltip {
            display: block;
        }

        .tooltip {
            display: none;
            font-size: 1.1rem;
            position: absolute;
            text-align: center;
            width: 9rem;
            left: -9.5rem;
            background-color: rgba(0,0,0,0.4);
            color: white;
            padding: 0 0.4rem;
            border-radius: 3px;
            line-height: 1.6;
            margin-right: 0.5rem;

            &.remove {
                width: 12rem;
                left: -12.5rem;
            }
        }
    }

}