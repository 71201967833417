.App {
  width: 100%;

  .video-listing-row {
    width: 100%;
    margin: 2rem 0 0 0;
    padding: 0 1rem;

    @media (min-width: 768px) {
        padding: 0;
      }
  }

  h1 {
    color: white;
    padding: 20px;
  }
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 11px;
}

@media (min-width: 1024px) {
  html, body {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  html, body {
    font-size: 14px;
  }
}

.journey-gradient-bg {
  background: rgb(139,199,237);
  background: -moz-linear-gradient(180deg, rgba(139,199,237,1) 0%, rgba(74,199,216,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(139,199,237,1) 0%, rgba(74,199,216,1) 100%);
  background: linear-gradient(180deg, rgba(139,199,237,1) 0%, rgba(74,199,216,1) 100%);
}