.mux-video-player-component {
    background: #0d0d0d;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    mux-player {
      height: 100%;
      margin: 0 auto;
    }
    mux-player::part(fullscreen){
      display: none;
    }
    mux-player::part(airplay){
      display: none;
    }
    mux-player::part(pip){
      display: none;
    }
    mux-player::part(cast){
      display: none;
    }
    .mux-player-error-copy{
      text-align: center;
      display: flex;
      color: #fff;
      width: 100%;
      justify-content: center;
    }
  }