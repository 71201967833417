.image-carousel {
    position: relative;
    margin-bottom: 4rem;
    
    .arrow-button{
        position: absolute;
        width: 4%;
        height: calc(100% - 26px - 10px);
        margin: 0;
        --padding-start: 0;
        --padding-end: 0;
        color: white;
        &.left{
            left: 0;
        }
        &.right{
            right: 0;
        }
        .arrow-icon{
            width: 30px;
            height: 50px;
        }
    }

    .swiper {
        white-space: nowrap;
        @media (max-width: 601px) {
            width: 70%;
        }
        @media (min-width: 601px) and (max-width: 900px) {
            width: 80%;
        }
        @media (min-width: 900px) and (max-width: 1000px) {
            width: 85%;
        }
        @media (min-width: 1000px) and (max-width: 1400px) {
            width: 90%;
        }
        @media (min-width: 1400px) {
            width: 92%;
        }
    }
}